#hint {
	i{
		font-size: 24px;
  		margin-top: -6px;
  	}
}
#advaced-search-link {
	margin-top: 12px;
}
#grid-view {
	font-size: 18px;
	margin-top: 5px;
	margin-right: 16px;
}
#list-view {
	font-size: 24px;
}

.accordion{	
	i.md-expand-more, i.md-chevron-right {
		font-size: 18px;
		margin-top: 6px;
	}
}
.md-file-upload {
	color: rgba(0,0,0,.26)
}
.md-videocam-off, .md-videocam {
	&.away {
		color: rgba(0,0,0,.26)
	}
}
.table-striped tbody tr:nth-of-type(odd) {
	&.disabled {
		background-color: #e5e5e5;
	}
}
.table-hover tbody tr.disabled {
	background-color: #e5e5e5;
	&:hover {
		background-color: #dbdbdb;
	}
}

.table-text {
	font-family: 'Lato', "Helvetica Neue", Helvetica, Arial, sans-serif;
	color: rgba(0, 0, 0, 0.54);
	&:hover {
		color: rgba(0, 0, 0, 1);
	}
	&:active {
		color: rgba(0, 0, 0, 0.54);
	}
}