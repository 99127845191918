.suto-table-responsive {
	.suto-table {
		width:100%;
		font-weight: bold;
		font-size: 14px;
		display: table;
		margin-bottom: 0px;
		text-align: center;
		.th {
			display: table-header-group;
		}
		.tr {
			display: table-row;
			.table-cell {
				white-space: nowrap;
				padding:8px;
				min-height:36px;
				min-width: 30px;
				border-bottom: 1px solid #dddddd;
				i.md-expand-more {
					margin-left:5px;
					font-size: 12px;
					font-weight:400;
				}
			}
			&.deactivated {
				background-color: #eaeaea;
				&:hover {
					background-color: #dbdbdb;
				}
			}
		}
		.tb {
			display:table-row-group;
			.tr:last-child {
				.table-cell {
					border: 0px;
				}
			}
		}
	}
}
