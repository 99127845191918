#logo-slogan {
	max-width:900px; 
	width:90%; 
	padding-top:70px;
	img {
		max-width:386px;
	}
	h4 {
		margin-top:-5px;
		margin-bottom:50px;
	}
}
#share-page-content {
	max-width:900px;
	width:90%;
	.main-section {
		padding:16px;
	}
}
#share-section, #ad-section {	height: 400px;}
#share-section {
	padding: 60px 36px;
	&:after {
		content: '';
		position: absolute;
		border-style: solid;
		border-width: 0 22px 25px;
		border-color: #FFFFFF transparent;
		display: block;
		width: 0;
		z-index: 1;
		margin-left: -22px;
		top: -25px;
		left: 50%;
	}
}
#ad-section {
	padding: 36px 36px;
}
#more-news {
	bottom: 36px;
  	right: 36px;
}	
