
	.table tbody tr.message {
		td{
			padding: 10px; 
			padding-top:12px; 
			padding-bottom:12px;
	  		text-align:left;
	  		span {
	  			text-align:left;
	  		}
	   }
		cursor: pointer;
		td:nth-child(1) {
			width:33px;
		}
		td:nth-child(2){
			width:260px;
			font-size:14px;
			span{
				span {
					display: block;
					font-size:12px;
					text-align:left;
					span {
						font-size: 14px;
						font-weight: 700;
					}
				}
			}
		}
		td:nth-child(3) {
			font-size:16px;
			font-weight: 700;
		}
		td:nth-child(4){
			width:150px;
			font-size:12px;
		}
		td:nth-child(5) {
			width:85px;
			font-size:12px;
		}
		td:nth-child(6){
			width:156px;
			font-size:12px;
		}
		td:nth-child(7){
			width:33px;
		}
		&.unread {
			font-weight: 700;
			td:nth-child(3) { 
				color: #36a1da;
			}				
		}
	}
	.table-hover tbody tr.read {
		background-color: #eaeaea;
		&:hover {
			background-color: #dbdbdb;
		}
	}
