html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
}

div#portal {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-family: 'Lato', "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: rgba(0, 0, 0, .54);

}

.prompt-overlay{
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 20px;
  text-align: center;
  background-color: #fff;
  z-index: 999;
  .md-clear{
    font-size: 20px;
    position: relative;
    top: -13px;
    right: -5px;
  }
  span.prompt-text{
    display: block;
    margin-bottom: 20px;
    i{
      font-size: 20px;
      position: relative;
      top: 4px;
    }
  }
}
.panel.panel-default{
  position: relative;
}
.a250{
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  -ms-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}

.user-row.loading{
  background-color: #ccc;
}

.user-row{
  .has-error{
    span{
      font-size: 12px;
      color: $red;
      text-align: center;
    }
  }
}

div#portal, .portal-modal {
  /*colors*/
  .light_gray {
    background-color: #f6f6f6;
  }
  .blue {
    background-color: #36a1da;
  }
  .white {
    background-color: #ffffff;
  }
  .red {
    background-color: #d8534f;
  }
  .charcoal {
    background-color: #474747;
    color: #ffffff;
  }
  .new-blue {
    background-color: #0071a2;
    color: rgba(255, 255, 255, 0.75);
  }
  /*borders*/
  .border-white-thick {
    border: 2px solid #ffffff;
  }
  .noborder {
    border: 0px;
  }
  /*fonts*/
  /*color*/
  .text-white {
    color: #ffffff;
  }
  .text-darker-blue {
    color: #1e76a5;
  }
  .text-blue {
    color: #36a1da;
  }
  .text-black54 {
    color: rgba(0, 0, 0, .54);
  }
  .text-black26 {
    color: rgba(0, 0, 0, .26);
  }
  .text-green {
    color: #80c569;
  }
  .text-red {
    color: #d9534f;
  }
  .text-yellow {
    color: #febf32;
  }
  .text-orange {
    color: #fe9431;
  }
  /*typogrophy*/
  h1 {
    font-weight: 300;
  }
  .text-left {
    text-align: left;
  }
  .text-right {
    text-align: right;
  }
  .text-center {
    text-align: center;
  }
  .thin-font {
    font-weight: 300;
  }
  .regular-font {
    font-weight: 400;
  }
  .bold {
    font-weight: 700;
  }
  /*corners*/
  .big-rounded-corners {
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
  }
  .rounded-corners {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }
  /*display*/
  .block {
    display: block;
  }
  .inline-block {
    display: inline-block;
  }
  .inline {
    display: inline;
  }
  .hide {
    display: none;
  }
  .table {
    display: table;
  }
  .table-cell {
    display: table-cell;
  }
  /*positioning*/
  .fixed {
    position: fixed;
  }
  .absolute {
    position: absolute;
  }
  .relative {
    position: relative;
  }
  /*float*/
  .left {
    float: left;
  }
  .right {
    float: right;
  }
  .nofloat {
    float: none;
  }
  .clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
  * html .clearfix {
    zoom: 1;
  }
  /* IE6 */
  *:first-child + html .clearfix {
    zoom: 1;
  }
  /* IE7 */
  /*centering*/
  .center {
    margin-left: auto;
    margin-right: auto;
  }
  /*padding*/
  .nopad {
    padding: 0px;
  }
  .pad5 {
    padding: 5px;
  }
  .pad10 {
    padding: 10px;
  }
  .pad15 {
    padding: 15px;
  }
  .pad20 {
    padding: 20px;
  }
  .pad30 {
    padding: 30px;
  }

  .nopad-left {
    padding-left: 0px;
  }
  .pad5-left {
    padding-left: 5px;
  }
  .pad10-left {
    padding-left: 10px;
  }
  .pad15-left {
    padding-left: 15px;
  }
  .pad20-left {
    padding-left: 20px;
  }
  .pad30-left {
    padding-left: 30px;
  }

  .nopad-top {
    padding-top: 0px;
  }
  .pad5-top {
    padding-top: 5px;
  }
  .pad10-top {
    padding-top: 10px;
  }
  .pad15-top {
    padding-top: 15px;
  }
  .pad20-top {
    padding-top: 20px;
  }
  .pad30-top {
    padding-top: 30px;
  }

  .nopad-right {
    padding-right: 0px;
  }
  .pad5-right {
    padding-right: 5px;
  }
  .pad10-right {
    padding-right: 10px;
  }
  .pad15-right {
    padding-right: 15px;
  }
  .pad20-right {
    padding-right: 20px;
  }
  .pad30-right {
    padding-right: 30px;
  }

  .nopad-bottom {
    padding-bottom: 0px;
  }
  .pad5-bottom {
    padding-bottom: 5px;
  }
  .pad10-bottom {
    padding-bottom: 10px;
  }
  .pad15-bottom {
    padding-bottom: 15px;
  }
  .pad20-bottom {
    padding-bottom: 20px;
  }
  .pad30-bottom {
    padding-bottom: 30px;
  }
  /*margin*/
  .nomargin {
    margin: 0px;
  }
  .margin5 {
    margin: 5px;
  }
  .margin10 {
    margin: 10px;
  }
  .margin15 {
    margin: 15px;
  }
  .margin20 {
    margin: 20px;
  }
  .margin30 {
    margin: 30px;
  }

  .nomargin-left {
    margin-left: 0px;
  }
  .margin5-left {
    margin-left: 5px;
  }
  .margin10-left {
    margin-left: 10px;
  }
  .margin15-left {
    margin-left: 15px;
  }
  .margin20-left {
    margin-left: 20px;
  }
  .margin30-left {
    margin-left: 30px;
  }

  .nomargin-top {
    margin-top: 0px;
  }
  .margin5-top {
    margin-top: 5px;
  }
  .margin10-top {
    margin-top: 10px;
  }
  .margin15-top {
    margin-top: 15px;
  }
  .margin20-top {
    margin-top: 20px;
  }
  .margin30-top {
    margin-top: 30px;
  }

  .nomargin-right {
    margin-right: 0px;
  }
  .margin5-right {
    margin-right: 5px;
  }
  .margin10-right {
    margin-right: 10px;
  }
  .margin15-right {
    margin-right: 15px;
  }
  .margin20-right {
    margin-right: 20px;
  }
  .margin30-right {
    margin-right: 30px;
  }

  .nomargin-bottom {
    margin-bottom: 0px;
  }
  .margin5-bottom {
    margin-bottom: 5px;
  }
  .margin10-bottom {
    margin-bottom: 10px;
  }
  .margin15-bottom {
    margin-bottom: 15px;
  }
  .margin20-bottom {
    margin-bottom: 20px;
  }
  .margin30-bottom {
    margin-bottom: 30px;
  }
  /*widths / heights*/
  .wide100 {
    width: 100%;
  }
  /*cursor*/
  .pointer {
    cursor: pointer;
  }
  .cursor-default {
    cursor: default;
  }
  /*full back*/
  .full-back {
    .fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }

  /*action*/
  .clickable-blue {
    &:hover {
      color: darken(hsl(200, 80%, 50%), 10%);
    }
    &:active {
      color: darken(hsl(200, 80%, 50%), 15%);
    }
    .pointer;
  }
  .clickable-red {
    &:hover {
      color: darken(hsl(0, 74%, 50%), 10%);
    }
    &:active {
      color: darken(hsl(0, 74%, 50%), 15%);
    }
    .pointer;
  }
  .clickable-green {
    &:hover {
      color: darken(hsl(90, 80%, 50%), 10%);
    }
    &:active {
      color: darken(hsl(90, 80%, 50%), 15%);
    }
    .pointer;
  }
  .clickable-white {
    &:hover {
      color: rgba(255, 255, 255, .7);
    }
    &:active {
      color: rgba(255, 255, 255, .5);
    }
    .pointer;
  }
  .clickable-pure-white {
    &:hover {
      color: rgb(255, 255, 255);
    }
    &:active {
      color: rgba(255, 255, 255, .9);
    }
    .pointer;
  }
  .clickable-black {
    &:hover {
      color: rgba(0, 0, 0, .75);
    }
    &:active {
      color: rgba(0, 0, 0, .85);
    }
    .pointer;
  }
  .clickable-red-back {
    &:hover {
      background-color: darken(hsl(0, 74%, 50%), 10%);
    }
    &:active {
      background-color: darken(hsl(0, 74%, 50%), 15%);
    }
    .pointer;
  }

  /*tooltip*/
  .tooltip {
    position: absolute;
    z-index: 1020;
    display: block;
    visibility: visible;
    padding: 5px;
    font-size: 11px;
    opacity: 0;
    filter: alpha(opacity=0);
  }
  .tooltip.in {
    opacity: .9;
    filter: alpha(opacity=90);
  }
  .tooltip.top {
    margin-top: -2px;
  }
  .tooltip.right {
    margin-left: 2px;
  }
  .tooltip.bottom {
    margin-top: 2px;
  }
  .tooltip.left {
    margin-left: -2px;
  }
  .tooltip.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #474747;
  }
  .tooltip.left .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -5px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #474747;
  }
  .tooltip.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #474747;
  }
  .tooltip.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid #474747;
  }
  .tooltip-inner {
    max-width: 200px;
    padding: 3px 8px;
    color: #fff;
    text-align: center;
    text-decoration: none;
    background-color: #474747;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    padding: 10px;
  }
  .tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
  }
  /*table*/
  .table {
    border-collapse: separate;
    thead {
      tr {
        th {
          .text-center;
          vertical-align: middle;
          border: 0px;
          .md.md-expand-more {
            .margin5-left;
            font-size: 12px;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          .text-center;
          vertical-align: middle;
          border-bottom: 1px solid #ddd;
          border-top: 0px;
        }
      }
    }
  }

  .valign {
    vertical-align: middle;
  }

  /*on-off*/
  .onoffswitch {
    position: relative;
    width: 55px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  .onoffswitch-checkbox {
    display: none;
  }
  .onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border-radius: 50px;
    -moz-transition: all 0.3s ease-in 0s;
    -webkit-transition: all 0s ease-in 0.3s;
    -o-transition: all 0.3s ease-in 0s;
    transition: all 0s ease-in 0.3s;
  }
  .onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    -moz-transition: all 0.3s ease-in 0s;
    -webkit-transition: all 0.3s ease-in 0s;
    -o-transition: all 0.3s ease-in 0s;
    transition: all 0.3s ease-in 0s;
  }
  .onoffswitch-inner:before, .onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 20px;
    padding: 0;
    line-height: 20px;
    font-size: 12px;
    color: white;
    font-family: Trebuchet, Arial, sans-serif;
    font-weight: bold;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .onoffswitch-inner:before {
    content: "Yes ";
    padding-left: 10px;
    background-color: #80C569;
    color: #FFFFFF;
  }
  .onoffswitch-inner:after {
    content: "No";
    padding-right: 10px;
    background-color: #d8534f;
    color: #ffffff;
    text-align: right;
  }
  .onoffswitch-switch {
    border: 2px solid #80C569;
    display: block;
    width: 20px;
    height: 20px;
    margin: 0px;
    background: #FFFFFF;
    border-radius: 50px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 35px;
    -moz-transition: all 0.3s ease-in 0s;
    -webkit-transition: all 0.3s ease-in 0s;
    -o-transition: all 0.3s ease-in 0s;
    transition: all 0.3s ease-in 0s;
  }
  .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
  }
  .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px;
  }
  .onoffswitch-label.off {
    .onoffswitch-switch {
      border: 2px solid #d8534f;

    }
  }
  .onoffswitch.disabled.off {
    .onoffswitch-inner {
      margin-left: -100%;
    }
    .onoffswitch-inner:before {
      background-color: #d2d2d2;
    }
    .onoffswitch-inner:after {
      background-color: #d2d2d2;
    }
    .onoffswitch-switch {
      border: 2px solid #d2d2d2;
      right: 35px;
    }
  }

  .onoffswitch.disabled.on {
    .onoffswitch-inner {
      margin-left: 0;
    }
    .onoffswitch-inner:before {
      background-color: #d2d2d2;
    }
    .onoffswitch-inner:after {
      background-color: #d2d2d2;
    }
    .onoffswitch-switch {
      border: 2px solid #d2d2d2;
      right: 0px;
    }
  }

  .boxed-in {
    margin-bottom: -2px;
  }
  /*select*/
  select {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    .pointer;
  }
  @media (min-width: 768px) {
    #advanced-search-modal .modal-dialog {
      width: 500px;
      margin: 30px auto;
    }
  }

  ul.nav.nav-tabs {
    margin-bottom: 20px;
  }
  @font-face {
    font-family: 'md-icons';
    src: url('fonts/Material-Design-Iconic-Font.eot?v=1.0.1');
    src: url('fonts/Material-Design-Iconic-Font.eot?#iefix&v=1.0.1') format('embedded-opentype'), url('fonts/Material-Design-Iconic-Font.woff?v=1.0.1') format('woff'), url('fonts/Material-Design-Iconic-Font.ttf?v=1.0.1') format('truetype'), url('fonts/Material-Design-Iconic-Font.svg?v=1.0.1#Material-Design-Iconic-Font') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  /*page imports*/
  //@import "login.less";
  @import "nav.less";
  @import "events.less";
  @import "messages.less";
  @import "share.less";
  @import "schedule.less";
  @import "video.less";
  /*@import '../../material-design-iconic-font/css/material-design-iconic-font.min.css';*/
}

#portal-appbar {
  height: 60px;
  width: 100%;
  background-color: #37a2db;
  position: fixed;
  z-index: 1040;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
}

#portal-content .suto-table-responsive {
  overflow: visible;
}

.suto-table {
  table-layout: fixed;
}

.suto-table-responsive {
  overflow: visible;
}

// angular-ui-switch overrides
.switch.extra-wide {
  width: 120px;
}

.switch.extra-wide.checked small {
  left: 92px;
}

// .panel-heading {
//   height: 50px;
// }

.toggle-filter-button {
  float: right;
}

.do-clear {
  clear: both;
}

.end-of-wizard-path-text {
  color: rgb(159, 20, 20);
  font-weight: 400;
}

.clear-filter-text {
  display: block;
  width: 100%;
  text-align: right;
}

.text-hover-bold {
  &:hover {
    font-weight: 300;
    text-decoration: underline;
  }
}

.arrow {
  cursor: default;
}

#player-match-info {
  position: fixed;
  z-index: 1049;
  left: 170px;
  display: inline-block;
  margin-left: 10px;
  font-weight: 100;
  height: 60px;
  line-height: 10px;
  //padding-top: 15px;
  p {
    font-size: 20px;
  }
  div {
    float: left;
    display: inline-block;
  }
}

#event-filter-advanced-search {
  ul.dropdown-menu {

    span.display-text {
      font-size: 16px;
    }

    span.tag-text {
      font-size: 12px;
    }

    li.active {
    background-color: #36a1da;
    //color: white;
    }

    // highlighted row
    li.active div.eventFilterTypeaheadTemplate {
      span.matched {
        color: white;
        font-weight: bold;
      }
    }

    // not highlighted row
    li div.eventFilterTypeaheadTemplate {
      span.matched {
        color: black;
        font-weight: bold;
      }
    }
  }

  span.tag {
    display: inline-block;
    border-radius: 3px;
    padding: 0px 3px;
    font-size: 12px;
    margin: 3px 0px;
  }

  span.tag-type {
    background-color: #868686;
    color: white;
  }

  span.tag-remaining {
    background-color: #318bbb;
    color: white;
  }
}




#presets-modal {
  .suto-table-responsive {
    .suto-table {
      font-size: 11px;
      .th {

  		}
  		.tr {
        &:nth-child(2n+2) {
          background-color: #ececec;
        }
  			.table-cell {
  				padding: 2px;
  			}
        .first-col {
          width: 100px;
          text-align: left;
          padding-left: 10px;
          background-color: rgba(195, 195, 195, 0.25);
        }
        .total-row {
          background-color: #a7a7a7;
        }
        .charcoal {
          background-color: #474747;
        }
        .hover-highlight {
          cursor: pointer;
          &:hover {
            background-color: #36a1da;
            color: white;
          }
        }
        .active {
          background-color : #36a1da;
          color: white;
        }
      }
    }
  }
}

@import '../../styles-includes/bootstrap-datepicker.min.css';
@import '../../styles-includes/bootstrap/css/bootstrap.min.css';
@import '../../styles-includes/angular-toastr.min.css';

// div#portal #event-table .font-size-12 {
//   font-size: 12px;
// }

div#portal #event-table {
  // .suto-table-responsive {
    .suto-table {
      font-size: 12px;
      .th {
       font-size: 12px;
  		}
  		.tr {
        font-size: 12px;
        // &:nth-child(2n+2) {
        //   background-color: #ececec;
        // }
  			// .table-cell {
  			// 	padding: 2px;
  			// }
        // .first-col {
        //   width: 100px;
        // }
        // .hover-highlight {
        //   cursor: pointer;
        //   &:hover {
        //     background-color: #36a1da;
        //     color: white;
        //   }
        // }
        // .total-row {
        //   background-color: #a7a7a7;
        // }
        // .active {
        //   background-color : #36a1da;
        //   color: white;
        // }
      }
    }
  // }
}

.pad5-left {
  padding-left: 5px;
}

.pad5-right {
  padding-right: 5px;
}

div.modal-x-large {
  @media (min-width: 768px) {
    width: 900px;
  }
}

div.modal-xx-large {
  @media (min-width: 768px) {
    width: 1300px;
  }
}

div#portal {
  .border0-top-left-radius {
    border-top-left-radius: 0px;
  }
  .border0-bottom-left-radius {
    border-bottom-left-radius: 0px;
  }
  .border0-top-right-radius {
    border-top-right-radius: 0px;
  }
  .border0-bottom-right-radius {
    border-bottom-right-radius: 0px;
  }
  .font-size10 {
    font-size: 10px;
  }
  .font-size11 {
    font-size: 11px;
  }
  .font-size12 {
    font-size: 12px;
  }
  .font-size13 {
    font-size: 13px;
  }
  .font-size14 {
    font-size: 14px;
  }
  .font-size15 {
    font-size: 15px;
  }
  .font-size16 {
    font-size: 16px;
  }
  .font-size17 {
    font-size: 17px;
  }
  .font-size18 {
    font-size: 18px;
  }
  .font-size19 {
    font-size: 19px;
  }
  .font-size20 {
    font-size: 20px;
  }
  .font-size21 {
    font-size: 21px;
  }
  .font-size22 {
    font-size: 22px;
  }
  .font-size23 {
    font-size: 23px;
  }
  .font-size24 {
    font-size: 24px;
  }
  .font-size25 {
    font-size: 25px;
  }
}

div#analysis-pages {
  .right {
    float: right;
  }

  .pad0 {
    padding: 0px;
  }
  .pad5 {
    padding: 5px;
  }
  .pad10 {
    padding: 10px;
  }

  .pad0-top-bottom {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .margin5-right {
    margin-right: 5px;
  }

  .height10 {
    height: 10px;
  }
  .height10 {
    height: 20px;
  }

  .width50 {
    width: 50%;
  }
  .width100 {
    width: 100%;
  }

  .text-center {
    text-align: center;
  }

  .hover-light-gray {
    &:hover {
      background-color: lightgray;
    }
  }

  .active-blue {
    background-color: #37a2db;
  }

  .active-text-blue {
    color: #37a2db;
    font-weight: bold;
  }

  button {
    &:disabled {
      background-color: red;
      opacity: .5;
    }
  }
}

#events {
  .searchGroup {
    width: 100%;
    .groupBox {
      float: left;
      display: inline-block;
    }
    .search-input {
      width: 70%;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    .presets-button {
      width: 30%;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
  div.ui-select-match {
    span {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    input {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
}

.filterModalListItem {
  width: 100%;
  text-align: center;
  line-height: 25px;
  border-bottom: 1px solid rgb(133, 133, 133);
  cursor: pointer;
  &:nth-child(2n+1) {
    background-color: #f1f1f1;
  }
  &:hover {
    background-color: #36a1da;
    color: white;
  }
}

// .top-separator {
//   border-top: 1px solid red;
// }

#share-content {
  .text-align-center {
    text-align: center;
  }
}

.material-design-iconic-font-disclaimer {
  font-size: 10px;
  color: #cbcbcb;
  margin-top: 50px;
  a {
    color: #cbcbcb;
  }
  &.page-bottom {
    position: fixed;
    bottom: 0px;
  }

  &.blue-disclaimer {
    color: #58c3fc;

    a {
      color: #58c3fc;
    }
  }
}
